import React from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import HomeCardMedia from "./HomeCardMedia";

const LeftContent = ({ data, onReadMore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "70%" },
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
          <HomeCardMedia data={data} minHeight={'70%'} maxHeight={'80%'} />

      {/* Content Excerpt */}
      <div
        style={{
          width: "90%",
          padding: "16px",
          fontSize: "16px",
          lineHeight: "1.6",
          fontFamily: "Arial, sans-serif",
          height: isMobile ? "auto" : "200px", // Only fixed height on larger screens
          overflow: "hidden",
          position: "relative",
          textAlign: "justify",
          display: "block",
        }}
      >
        <p
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: isMobile ? "none" : "10",
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
          }}
        >
          {data?.content}
        </p>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={onReadMore}
        sx={{
          marginTop: "8px",
          fontWeight: "bold",
          fontSize: { xs: "14px", sm: "16px" },
          padding: { xs: "6px 12px", sm: "8px 16px" },
          borderRadius: "5px",
        }}
      >
        Read More
      </Button>
    </Box>
  );
};

export default LeftContent;
