import React, { useState, useEffect, useRef } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Box,
    Select,
    MenuItem,
    FormControl,
    Tooltip,
    IconButton,
    TextField,
} from "@mui/material";
import RichTextEditor from "./RichTextEditor";
// Color options with their display names and hex values
import axios from "axios";
import { API_URL } from "../../consts";

const SummaryEditorDialog = ({ open, onClose, onSave, objectId }) => {
    const initialContent = JSON.stringify([
        {
          type: 'paragraph',
          children: [{ text: 'Initial content' }],
        },
      ]);
      
    const [updatedContent, setUpdatedContent] = useState('')
    const [originalContent, setOriginalContent] = useState('')

    const editorRef = useRef();
    const decodeBase64 = (str) => decodeURIComponent(escape(atob(str)));

    useEffect(() => {
        if (open && objectId && !updatedContent) {
            const fetchData = async () => {
                let url = API_URL + `/documents/rates/` + objectId;

                try {
                    const response = await axios.get(url);
                    console.log('decodd1e')

                    // const decodedContent = decodeURIComponent(escape(atob(response.data.content)));
                    // console.log(decodedContent)
                    const decodedString = decodeBase64(response.data.content);
                    setUpdatedContent(decodedString)
                    console.log('original:')
                    console.log(decodeBase64(response.data.original_content))
                    setOriginalContent( decodeBase64(response.data.original_content))
                } catch (err) {
                    console.log(err.message); // Handle the error
                } finally {
                    console.log('done')
                }
            }
            fetchData();

        };

    }, [open, objectId]);

    const handleChange = (updatedHtml) => {
        console.log(updatedHtml)
        setUpdatedContent(updatedHtml)
    }
    const handleClose = () => {
        onClose()
    }
    const handleSave = () => {
        console.log('save')
        console.log(editorRef)
        if (editorRef.current) {
            console.log('inside if')
            const htmlContent = editorRef.current.getContent(); // Get content from editor
            console.log('contatn to save')
            console.log(htmlContent)
            onSave(htmlContent);
            setUpdatedContent('')

        }
    };
    return (
        <Dialog open={open} onClose={handleClose} fullWidth
            BackdropProps={{
                style: {
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                },
            }}
            PaperProps={{
                style: {
                    width: "90vw",
                    height: "85%",
                    margin: "auto",
                    borderRadius: 8,
                },
            }}
            sx={{
                "& .MuiDialog-container": {
                    width: "100vw",
                    margin: 0,
                },
                "& .MuiDialog-paper": {
                    width: "100vw",
                    maxWidth: "100vw",
                },
            }}
        >
            <DialogContent>

                {open && <RichTextEditor ref={editorRef}
                    height={'500px'}
                    originalContent={originalContent}
                    defaultValue={updatedContent} />}

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="secondary">
                    Close
                </Button>
                <Button onClick={handleSave} variant="outlined" color="secondary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SummaryEditorDialog;