import React from "react";
import { Box, Rating, useTheme, useMediaQuery } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const RightInfoPanel = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "30%" },
        marginTop: { xs: "16px", sm: 0 },
        height: "100%",
        background: "linear-gradient(to right,#e7ebe4, #fcf9f9)",
        borderRadius: "10px",
        padding: "24px",
        overflow: "auto",
      }}
    >
      {/* Rating */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <Rating
          style={{ color: "rgba(255, 204, 72, 1)" }}
          value={data?.average_stars || 0}
          readOnly
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        <Box sx={{ ml: 2 }}>
          <span style={{ fontWeight: 500 }}>{data?.average_stars || 0}</span>
          <span style={{ color: "grey" }}> / 5</span>
        </Box>
      </Box>

      {/* Price */}
      {data?.price && (
        <Box sx={{ mb: 3 }}>
          <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Price</div>
          <div style={{ fontStyle: "italic", opacity: 0.8 }}>
            {data.currency
              ? `${data.price}${data.currency}`
              : data.price_label}
          </div>
        </Box>
      )}

      {/* Summary */}
      {data?.comment && (
        <Box sx={{ mb: 3 }}>
          <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Summary</div>
          <div
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "5px",
              padding: "10px",
              minHeight: "120px",
            }}
          >
            {data.comment}
          </div>
        </Box>
      )}

      {/* Tags */}
      {data?.tags?.length > 0 && (
        <Box>
          <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Tags</div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {data.tags.map((tag, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: "#e0e0e0",
                  padding: "4px 8px",
                  borderRadius: "16px",
                  fontSize: "0.875rem",
                }}
              >
                {tag}
              </span>
            ))}
          </div>
        </Box>
      )}
    </Box>
  );
};

export default RightInfoPanel;
