import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Button
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ChipList from "../common/ChipList";
import SpaceCardItem from "../Space/SpaceCardItem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TopicFilter from "../common/TopicFilter"
import { select } from "slate";
const CommunitySpaces = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [searhTags, setSearchTags] = useState([]);
  const [searchItem, setsearchItem] = useState("");
  const [filterBy, setFilterBy] = useState(["You may love.."]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removedRateId, setRemovedRateId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [topTopics, setTopTopics] = useState([]);
  const token = useSelector((state) => state.token);
  const setTokenInProcess = useSelector((state) => state.setTokenInProcess);

  let navigate = useNavigate();

  const topics = [
    "Climate Change",
    "Technology Innovations",
    "Artificial Intelligence",
    "Health and Wellness",
    "Mental Health",
    "Politics",
    "Economics",
    "Cryptocurrency",
    "Social Media",
    "Cybersecurity",
    "Education",
    "Sustainability",
    "Global Warming",
    "Space Exploration",
    "Renewable Energy",
    "Human Rights",
    "Science and Research",
    "Digital Transformation",
    "Data Privacy",
    "E-commerce",
    "Virtual Reality",
    "Food Security",
    "Travel and Tourism",
    "Entertainment Industry",
    "Workplace Dynamics",
    "Finance and Investment",
    "Cultural Trends",
    "Environmental Conservation",
    "Public Health",
    "Global Trade",
    "Sports",
    "Soccer",
  ];

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("yourItemKey");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const deleteRate = () => {
    let url = API_URL + `/rates/${removedRateId}/delete`;
    axios
      .post(url, null)
      .then((res) => {
        getSpacesRequest(searhTags, selectedTopics);
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
        dispatch({
          type: "SET_POPUP",
          payload: { text: "Rate removed successfully", type: "success" },
        });
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text:
              err.response?.data?.error ||
              "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
      });
  };



  const scrollRow = (direction, topic) => {
    const row = document.getElementById(`row-${topic}`);
    if (row) {
      const scrollAmount = direction === "left" ? -300 : 300;
      row.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const shouldDisplayArrows = (topic) => {
    const row = document.getElementById(`row-${topic}`);
    if (row) {
      return row.scrollWidth > row.clientWidth;
    }
    return false;
  };


  useEffect(() => {
    if (localStorage.getItem("is_extension_login")) {
      navigate("/extension-login-success");
    }
  }, []);

  useEffect(() => {
    console.log('set in process in cs', setTokenInProcess)
    if (!setTokenInProcess) {
      getSpacesRequest(searhTags, selectedTopics);

    }
  }, [filterBy, searhTags, selectedTopics, setTokenInProcess]);

  const getSpacesRequest = (tags, topics) => {
    let baseUrl = `/spaces/community`;
    if (token) {
      baseUrl = `/spaces/authenticated/community`;
    }
    let url = API_URL + baseUrl
    let paramsBody = {
      tags: tags.length > 0 ? tags.join(",") : null,
      topics: topics.length > 0 ? topics.join(",") : null,
    };
    axios
      .get(url, { params: paramsBody })
      .then((res) => setData(res.data.spaces))
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text:
              err.response?.data?.error ||
              "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
      });
  };


  return (
    <Grid container spacing={2} style={{ paddingTop: "34px", width: "100%" }}>
      <Grid item xs={12}>
        <h2>Explore community spaces </h2>
        <TopicFilter
          onSelect={(topics) => {
            console.log(topics)
            setFilterBy(topics);
            setSelectedTopics(topics);
          }}
          firstTopics={topTopics}
        />
        <FormControl className="searchbar" fullWidth variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
          <Input
            fullWidth
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchTags([...searhTags, searchItem]);
                setsearchItem("");
              }
            }}
            className="searchBox"
            id="standard-adornment-password"
            value={searchItem}
            onChange={(e) => setsearchItem(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <ChipList list={searhTags} onDelete={(index) => setSearchTags(searhTags.filter((_, i) => i !== index))} />
      </Grid>
      <Grid item xs={12}>
        <h3>You may love..</h3>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', padding: '16px', margin: '0 auto'}}
        >
          {data?.map((item, index) => (
            <div key={index} style={{ flex: "0 0 auto", margin: "0 10px", width: '400px',height:'420px' ,paddingTop:'20px'}}>
              <SpaceCardItem
                isBookmark={true}
                data={item}
                searhTags={searhTags}
                applyMargin={true}
                showRemove={false}
                onRemove={(id) => {
                  setRemoveDialogOpen(true);
                  setRemovedRateId(id);
                }}
                onRateSuccessfully={() => getSpacesRequest(searhTags, selectedTopics)}
              />
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={12}>
  
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {topics.map((topic) => (
            <MenuItem key={topic}>
              <Checkbox
                checked={selectedTopics.includes(topic)}
                onChange={() =>
                  setSelectedTopics((prev) =>
                    prev.includes(topic) ? prev.filter((t) => t !== topic) : [...prev, topic]
                  )
                }
              />
              {topic}
            </MenuItem>
          ))}
        </Menu>
      </Grid>

      <ConfirmationDialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        onConfirm={() => {
          deleteRate();
          setRemoveDialogOpen(false);
        }}
        title={"Delete rate"}
        cancelButtonString={"Cancel"}
        confirmButtonString={"Delete"}
        message={"Are you sure you want to delete this rate?"}
      />
    </Grid>
  );
};

export default CommunitySpaces;
