import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
    Box,
    Button,
    Input,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Switch,
    FormControlLabel,
    Typography,
    TextField
} from "@mui/material";
import HighlightOff from "@mui/icons-material/HighlightOff";
import { pink } from "@mui/material/colors";

// Dialog styling
const dialogStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
};

/**
 * @param {Object} props
 * @param {boolean} props.open - Whether the modal is open
 * @param {Function} props.onClose - Function to close the modal
 * @param {Function} props.onAddCollaboratorClick - Callback when adding a new collaborator
 * @param {Array} props.collaborators - List of collaborators in the space
 * @param {Function} props.onDeleteCollaboratorClick - Callback to remove a collaborator
 * @param {Function} props.onDeleteSpaceClick - Callback to delete the entire space
 * @param {boolean} [props.isSpaceEmpty=true] - Whether the space is empty (enables "share with community" switch)
 */
const SettingsDialog = ({
    spaceData,
    onChangeSpace,
    open,
    onClose,
    onAddCollaboratorClick,
    collaborators,
    onDeleteCollaboratorClick,
    onDeleteSpaceClick,
    isSpaceEmpty = true
}) => {
    // Local state for adding collaborators
    const [collaboratorEmail, setCollaboratorEmail] = useState("");

    // Local state for settings switches
    const [isSpacePublic, setIsSpacePublic] = useState(spaceData.is_public);
    const [shareWithCommunity, setShareWithCommunity] = useState(spaceData.is_community);

    // Get current page URL (for demonstration purposes)
    const currentPageURL =
        typeof window !== "undefined" ? window.location.href : "http://example.com";

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box sx={dialogStyle}>
                    {/* ----- Settings Section ----- */}
                    <Typography variant="h6" gutterBottom>
                        Space Settings
                    </Typography>

                    {/* Public/Private Switch */}
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isSpacePublic}
                                    onChange={(e) => {
                                        const updatedSpace = { ...spaceData, is_public: e.target.checked };
                                        setIsSpacePublic(e.target.checked);
                                        onChangeSpace(updatedSpace);
                                    }}
                                />
                            }
                            label="Make space public"
                        />

                        {/* Explanation and Space URL if public */}
                        {isSpacePublic && (
                            <Box ml={{ xs: 2, sm: 6 }} mt={1}>
                                <Typography variant="body2" color="textSecondary">
                                    When the space is public, anyone with the link can access it.
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    <strong>Space URL:</strong>
                                </Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={currentPageURL}
                                    InputProps={{ readOnly: true }}
                                    sx={{ mt: 1 }}
                                />
                            </Box>
                        )}

                        {/* Additional explanation (shown regardless of switch status) */}
                        <Typography variant="body2" sx={{ ml: { xs: 2, sm: 6 }, mt: 1 }}>
                            If the space is made public, anyone with this URL can see it.
                        </Typography>
                    </Box>

                    {/* Share with Community Switch */}
                    {isSpacePublic && (
                        <Box mb={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={shareWithCommunity}
                                        onChange={(e) => {
                                            const updatedSpace = { ...spaceData, is_community: e.target.checked };
                                            setShareWithCommunity(e.target.checked);
                                            onChangeSpace(updatedSpace);
                                        }}
                                        disabled={!isSpaceEmpty}
                                    />
                                }
                                label="Share with community"
                            />
                            {shareWithCommunity && (
                                <Typography variant="body2" sx={{ ml: { xs: 2, sm: 6 } }}>
                                    This space will be discoverable on the community page by everyone.
                                </Typography>
                            )}
                            {!isSpaceEmpty && (
                                <Typography variant="body2" sx={{ ml: { xs: 2, sm: 6 }, color: "gray" }}>
                                    (Disabled because the space is not empty)
                                </Typography>
                            )}
                            <Typography variant="body2" sx={{ ml: { xs: 2, sm: 6 }, mt: 1 }}>
                                If shared with the community, anyone can find and access this space on
                                the community page.
                            </Typography>
                        </Box>
                    )}

                    {/* Delete Space Button */}
                    <Box mb={3} textAlign={{ xs: "center", sm: "left" }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => onDeleteSpaceClick?.()}
                        >
                            Delete Space
                        </Button>
                    </Box>

                    {/* ----- Collaborators Section ----- */}
                    <Typography variant="h6" gutterBottom>
                        Collaborators:
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
                        All the collaborators in the space can see the space items.
                    </Typography>

                    {/* Add Collaborator Input/Btn */}
                    <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} mb={2}>
                        <Box width={{ xs: "100%", sm: "300px" }} mr={{ sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                            <Input
                                fullWidth
                                value={collaboratorEmail}
                                onChange={(e) => setCollaboratorEmail(e.target.value)}
                                placeholder="Enter collaborator's email"
                            />
                        </Box>
                        <Button
                            onClick={() => {
                                onAddCollaboratorClick?.(collaboratorEmail);
                                setCollaboratorEmail("");
                            }}
                            variant="contained"
                        >
                            Add Collaborator
                        </Button>
                    </Box>

                    {/* Collaborators Table */}
                    <TableContainer
                        component={Paper}
                        sx={{
                            maxHeight: "200px",
                            overflowY: "auto",
                        }}
                    >
                        <Table
                            sx={{ minWidth: 250 }}
                            stickyHeader
                            aria-label="collaborators table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {collaborators.map((c) => (
                                    <TableRow key={c.email}>
                                        <TableCell align="left">{c.name}</TableCell>
                                        <TableCell align="left">{c.email}</TableCell>
                                        <TableCell align="left">
                                            <HighlightOff
                                                style={{ cursor: "pointer" }}
                                                sx={{ color: pink[500] }}
                                                onClick={() => onDeleteCollaboratorClick?.(c.email)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Fade>
        </Modal>
    );
};

export default SettingsDialog;
