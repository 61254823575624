import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    Paper,
    Alert,
} from '@mui/material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

/**
 * A reusable dialog for cropping images. 
 *
 * Props:
 *   - open: boolean, whether dialog is open
 *   - handleCancelImage: function to handle closing/cancel
 *   - applyCrop: function to apply/confirm the crop
 *   - upImg: the image data URL to display in the crop
 *   - crop: the crop object ({ unit: '%', width: 80, aspect: 16/9, etc. })
 *   - setCrop: setter for the crop object
 *   - onCropComplete: callback when the crop is completed
 *   - imgRef: ref to the <img> element
 *   - onImageLoad: callback when the image loads
 */
const CropDialog = ({
    open,
    handleCancelImage,
    applyCrop,
    upImg,
    crop,
    setCrop,
    onCropComplete,
    imgRef,
    onImageLoad,
}) => {
    // Track if the user’s selected crop area exceeds 300px × 300px
    const [isLargeCrop, setIsLargeCrop] = useState(false);

    // A small wrapper to capture pixel-based dimensions from ReactCrop
    const handleCropComplete = (cropPercentages, pixelCrop) => {
        // Call the parent’s onCropComplete if needed
        console.log('crop complete')
        console.log(pixelCrop)
        onCropComplete(cropPercentages);

        if (pixelCrop?.width > 60 || pixelCrop?.height > 35) {
            setIsLargeCrop(true);
        } else {
            setIsLargeCrop(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancelImage}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    overflowY: 'visible', // so the dialog doesn't cut off shadows if needed
                },
            }}
        >
            {/* -- Title -- */}
            <DialogTitle
                sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pb: 1,
                }}
            >
                Crop Your Image
            </DialogTitle>

            <DialogContent
                dividers
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Use the drag handles to select the portion of the image you want to keep.
                </Typography>

                {/* Show a warning if crop area is bigger than 300×300 */}
                {isLargeCrop && (
                    <Alert severity="warning" sx={{ width: '100%' }}>
                        The selected area is larger than 300x300. It may appear low-quality or cropped
                        in smaller spaces, but you can still continue.
                    </Alert>
                )}
                {/* Disclaimer */}
                <Typography
                    variant="caption"
                    sx={{ color: 'text.secondary', fontStyle: 'italic', textAlign: 'center' }}
                >
                    Disclaimer: You are responsible for the content you upload. Do not upload
                    unsolicited, private, or sensitive information. If this space is marked as public,
                    the uploaded image may be accessible to others.
                </Typography>


                {/* -- The Crop Container -- */}
                {upImg && (
                    <Paper
                        elevation={3}
                        sx={{
                            p: 1,
                            backgroundColor: '#f9fafb',
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: '100%',
                            maxHeight: '60vh',
                            overflow: 'auto',
                        }}
                    >
                        <ReactCrop
                            crop={crop}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={handleCropComplete}
                            aspect={crop.aspect}
                            style={{ width: '100%', height: 'auto' }}
                        >
                            <Box
                                component="img"
                                ref={imgRef}
                                alt="Crop target"
                                src={upImg}
                                onLoad={onImageLoad}
                                sx={{
                                    maxWidth: '100%',
                                    maxHeight: '60vh',
                                    objectFit: 'contain',
                                }}
                            />
                        </ReactCrop>
                    </Paper>
                )}
            </DialogContent>

            {/* -- Actions -- */}
            <DialogActions
                sx={{
                    pt: 2,
                    px: 3,
                    pb: 3,
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancelImage}
                    sx={{ textTransform: 'none', mr: 1 }}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={applyCrop}
                    sx={{ textTransform: 'none' }}
                >
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CropDialog;
