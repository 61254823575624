import React, { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import "../style.css";

const ChipList = ({
  list = [],
  onDelete,
  highlightList = [],
  onClick,
  maxWidth = 350,
  onPlusClick,
  AllowChipOverflow = false, // Default to false to show all chips without restrictions
}) => {
  const [visibleChips, setVisibleChips] = useState(list);
  const [hiddenCount, setHiddenCount] = useState(0);

  useEffect(() => {
    if (!AllowChipOverflow || list.length === 0) {
      // If overflow is disabled or the list is empty, show all chips
      setVisibleChips(list);
      setHiddenCount(0);
      return;
    }

    // Calculate visible chips for up to two lines based on maxWidth
    let totalWidth = 0;
    let lineCount = 1;
    const chipWidths = []; // Simulate chip widths
    const visible = [];
    let hidden = 0;

    list.forEach((tag, index) => {
      const chipWidth = tag.length * 8 + 24; // Approximate width calculation
      if (totalWidth + chipWidth > maxWidth && lineCount < 2) {
        lineCount++;
        totalWidth = 0; // Reset for the next line
      }
      if (lineCount <= 2 && totalWidth + chipWidth <= maxWidth) {
        visible.push(tag);
        totalWidth += chipWidth;
      } else {
        hidden++;
      }
    });

    setVisibleChips(visible);
    setHiddenCount(hidden);
  }, [list, maxWidth, AllowChipOverflow]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap", // Allow wrapping for two lines
        overflow: AllowChipOverflow ? "hidden" : "visible", // Only hide overflow when enabled
        maxHeight: AllowChipOverflow ? "calc(2 * 48px)" : "none", // Limit to two lines of chips when enabled
        maxWidth: AllowChipOverflow ? `${maxWidth}px` : "none", // Apply max width only when enabled
      }}
    >
      {visibleChips.map((tag, index) => {
        const cond = highlightList?.includes(tag);
        return (
          <Chip
            key={index}
            className={cond ? "tagChipsOutlined cardTags" : "tagChips cardTags"}
            label={tag}
            color="primary"
            variant={cond ? "outlined" : "filled"}
            onDelete={onDelete ? () => onDelete(index) : null}
            onClick={onClick ? () => onClick(tag) : null}
          />
        );
      })}
      {AllowChipOverflow && hiddenCount > 0 && (
        <Chip
          label={`+${hiddenCount}`}
          color="secondary"
          onClick={onPlusClick ? () => onPlusClick(hiddenCount) : null}
        />
      )}
    </div>
  );
};

export default ChipList;
