import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import { InputBase } from '@mui/material';
import { ListItemButton, IconButton } from "@mui/material";
import { Toolbar } from "@mui/material";
import StarOutline from "@mui/icons-material/StarOutline";
import AddIcon from "@mui/icons-material/Add";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import MenuIcon from "@mui/icons-material/Menu"; // Import MenuIcon
import { useSelector, useDispatch } from "react-redux";
import { API_URL } from "../../consts";
import axios from "axios";
import CreateSpace from "../Space/CreateSpace";
import SplitscreenOutlined from "@mui/icons-material/SplitscreenOutlined";
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from "@mui/material";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MiniSidebar = ({ setProfile,onOpenChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const dontMinimizeFromLocalStorage = localStorage.getItem("dontMinimize");
  const [open, setOpen] = React.useState(dontMinimizeFromLocalStorage === "true");

  const [searchQuery, setSearchQuery] = React.useState("");

  const [isCreateSpaceOpen, setIsCreateSpaceOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [spaces, setSpaces] = React.useState([]);
  const [dontMinimize, setDontMinimize] = React.useState(
    dontMinimizeFromLocalStorage === "true"
  );

  const sidebarItems = [
    {
      title: "My Reviews",
      icon: (
        <StarOutline sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />
      ),
      path: "/my-reviews",
    },
    {
      title: "Community",
      icon: (
        <GroupsOutlinedIcon sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />
      ),
      path: "/community",
      children: [
        {
          icon: (<StarOutline sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)", marginRight: '10px' }} />
          ),
          title: "Reviews",
          path: "/community",
        },
        {
          icon: (<SplitscreenOutlined
            sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)", marginRight: '10px' }}
          />),
          title: "Spaces",
          path: "/community-spaces",
        }

      ]
    },

    {
      title: <div title="My Spaces" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <div>My Spaces</div>
        {open && (
          <div style={{ display: 'flex' }}>
            <div style={{
              width: '120px',
              width: '120px',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              padding: '4px 8px',
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',

            }}>
              <InputBase
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  fontSize: '14px',
                  width: '100%',
                  color: '#333',
                }}
              />
            </div>
            <div>
              <AddIcon title={'Add space'} sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)", margin: '10px' }}
                onClick={() => { setIsCreateSpaceOpen(true) }} />

            </div>
          </div>
        )}
      </div>
      ,
      icon: (
        <SplitscreenOutlined
          sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }}
        />
      ),
    },
  ];

  const sidebarItemsEnd = [];
  const token = useSelector((state) => state.token);

  React.useEffect(() => {
    onOpenChange(open)

  }, [open]);
  React.useEffect(() => {
    onOpenChange(mobileOpen)

  }, [mobileOpen]);
  React.useEffect(() => {
    if (token) {
      getSpaces();
    }
  }, [token]);

  // If on mobile, always keep open = true
  React.useEffect(() => {
    if (isMobile) {
      setOpen(true);
    }
  }, [isMobile]);
  const dispatch = useDispatch();

  const performGetSpaces = useSelector((state) => state.performGetSpaces);

  React.useEffect(() => {
    if (performGetSpaces) {
      getSpaces();
      dispatch({
        type: "GET_SPACES",
        payload: {
          performGetSpaces: false,
        },
      });
    }
  }, [performGetSpaces, dispatch]);

  const getSpaces = () => {
    let url = API_URL + `/spaces`;
    axios
      .get(url)
      .then((res) => {
        setSpaces(res.data.spaces);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChangeSwitch = () => {
    console.log('change')
    const newDontMinimize = !dontMinimize;
    setDontMinimize(newDontMinimize);
    localStorage.setItem("dontMinimize", newDontMinimize);
    if (!newDontMinimize) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  const handleDrawerToggle = () => {
    if (isMobile) {
      console.log('is mobile open?', !mobileOpen)
      setMobileOpen(!mobileOpen);
    } else {
      handleChangeSwitch();
    }
  };


  // Ref for the scrollable container
  const spacesContainerRef = React.useRef(null);

  // Function to scroll down the spaces list


  const handleDeleteSpace = (e, space) => {
    e.preventDefault()
    e.stopPropagation()
    // triggerEvent('deleteSpace', { spaceId: space.id })
    dispatch({
      type: "SET_DELETE_SPACE_DIALOG",
      payload: {
        deleteSpaceDialogOpen: true,
      },
    });
    console.log('cliked delete')
  }
  const handleCollaboratorsOpen = (e, space) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch({
      type: "SET_COLLABORATORS_DIALOG_OPEN",
      payload: {
        collobartorsDialogOpen: true,
      },
    });
  }

  const filteredSpaces = spaces.filter((space) =>
    space.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      onMouseEnter={() => ( !dontMinimize && !isMobile ? setOpen(true) : null)}
      onMouseLeave={() => (!dontMinimize && !isMobile ? setOpen(false) : null)}
    >
      <Toolbar sx={{ marginTop: "16px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        onMouseEnter={() => (!dontMinimize ? setOpen(true) : null)}
        onMouseLeave={() => (!dontMinimize ? setOpen(false) : null)}
      >
        <List>


          {/* First batch of sidebar items */}
          {sidebarItems.map((item) => (
            <React.Fragment key={item.title}>
              <ListItemButton
                sx={{
                  minHeight: 36,
                  justifyContent: open ? "initial" : "center",
                  px: 3,
                }}
                onClick={() => {
                  if (item.path) {
                    navigate(item.path);
                    if (isMobile) {
                      setMobileOpen(false)
                    }
                    setProfile(false);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "20px",
                    justifyContent: "center",
                    marginRight: "10px",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    disableTypography
                    primary={item.title}
                    sx={{
                      marginRight: "10px",
                      fontWeight: 300,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: open ? "300px" : "0px",
                    }}
                    title={item.title}
                  />
                )}
              </ListItemButton>

              {/* Render children if present */}
              {item.children && open && location && location.pathname.includes('community') && (
                <List sx={{ pl: 4 }}>
                  {item.children.map((child) => (
                    <ListItemButton
                      key={child.title}
                      sx={{
                        minHeight: 36,
                        justifyContent: "initial",
                        px: 3,
                      }}
                      onClick={() => {
                        navigate(child.path);
                        if (isMobile) {
                          setMobileOpen(false)
                        }
                        setProfile(false);
                      }}
                    >
                      {child.icon}

                      <ListItemText
                        disableTypography
                        primary={child.title}
                        sx={{
                          marginRight: "10px",
                          fontWeight: 300,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "300px",
                        }}
                        title={child.title}
                      />
                    </ListItemButton>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>

        {/* Scrollable container for spaces with custom scrollbar */}
        {open && (
          <Box
            sx={{
              height: "200px",
              overflowY: "auto",
              position: "relative",
              // Custom scrollbar styles
              "&::-webkit-scrollbar": {
                width: "6px",
                "-webkit-appearance": "none",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              "&::-webkit-scrollbar-button": {
                display: "none",
                width: 0,
                height: 0,
              },
              // For Firefox
              scrollbarWidth: "thin",
              scrollbarColor: "rgba(0, 0, 0, 0.3) transparent",
            }}
            ref={spacesContainerRef}
          >
            <List>
              {filteredSpaces.map((space) => (
                <ListItemButton
  key={space.id}
  sx={{
    justifyContent: open ? "initial" : "center",
    px: 8,
    position: "relative", // Important for absolute child positioning
  }}
  className={
    location.pathname.includes(`/spaces/${space.id}`)
      ? "highlightedSidebar"
      : "sidebarItem"
  }
  onClick={() => {
    navigate(`/spaces/${space.id}`);
    if (isMobile) {
      setMobileOpen(false);
    }
    setProfile(false);
  }}
>
  {open && (
    <ListItemText
      disableTypography
      className={
        location.pathname.includes(`/spaces/${space.id}`)
          ? "sidebarItemHigh"
          : "sidebarItem"
      }
      primary={space.name}
      sx={{
        opacity: open ? 1 : 0,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden", // Ensure ellipsis appears when text is too long
        maxWidth: open ? "calc(100% - 5px)" : "0px", // Leave space for the icon
      }}
      title={space.name}
    />
  )}

  {/* Only show settings icon if we're on a matching /spaces/:id route */}
  {location.pathname.includes(`/spaces/${space.id}`) && (
    <div
      style={{
        position: "absolute",
        top: 8,
        right: 8,
      }}
    >
      <Tooltip title="Settings" arrow>
        <SettingsOutlined
          style={{
            fontSize: 20,
            color: "rgba(0, 0, 0, 0.6)",
            cursor: "pointer",
            margin: "5px",
          }}
          onClick={(e) => handleCollaboratorsOpen(e, space)}
        />
      </Tooltip>
    </div>
  )}
</ListItemButton>


              ))}
            </List>
          </Box>
        )}

        <List>
          {/* Last batch of sidebar items */}
          {sidebarItemsEnd.map((item) => (
            <ListItemButton
              key={item.title}
              sx={{
                minHeight: 36,
                justifyContent: open ? "initial" : "center",
                px: 3,
                cursor: item.path ? "pointer" : "default",
              }}
              className={
                item.path === location.pathname
                  ? "highlightedSidebar"
                  : "sidebarItem"
              }
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                  if (isMobile) {
                    setMobileOpen(false)
                  }
                  setProfile(false);
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "20px",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
                className={
                  item.path === location.pathname ? "iconColor" : "sidebarItem"
                }
              >
                {item.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  disableTypography
                  className={
                    item.path === location.pathname
                      ? "sidebarItemHigh"
                      : "sidebarItem"
                  }
                  primary={item.title}
                  sx={{
                    marginRight: "10px",
                    fontWeight: 300,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: open ? "300px" : "0px",
                  }}
                  title={item.title}
                />
              )}
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  );
  return (
    <div>
      {/* Fixed Menu Icon in the top-left corner */}
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: "fixed",
          top: 16,
          left: 16,
          zIndex: 2000, // Above Drawer
          color: "#000",
          backgroundColor: "#fff",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        }}
      >
        <MenuIcon />
      </IconButton>
      {isMobile ? (
        // Mobile drawer
        <SwipeableDrawer
          variant="temporary"
          anchor="bottom"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{
            keepMounted: true, // Better mobile performance
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              width: { xs: '100%', sm: drawerWidth },  //  <-- Override to 100% on mobile
              boxSizing: 'border-box',
            },
          }}
        >
          {drawerContent}
        </SwipeableDrawer>
      ) : (
        // Desktop drawer
        <Drawer
          variant="permanent"
          transitionDuration={3000}
          open={open}
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}

      {isCreateSpaceOpen && (
        <CreateSpace
          open={isCreateSpaceOpen}
          handleClose={() => setIsCreateSpaceOpen(false)}
          onSpaceAdded={() => {
            getSpaces();
            setIsCreateSpaceOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default MiniSidebar;
