import React from 'react';
import { IconButton } from '@mui/material';
import { EditOutlined, SaveOutlined, CancelOutlined } from '@mui/icons-material';

/**
 * Subcomponent: Handles the name, description, and edit/save/cancel buttons.
 */
const NameDescriptionSection = ({
  isEditing,
  name,
  setName,
  description,
  setDescription,
  handleCancelClick,
  handleSaveClick,
  handleEditClick,
  StyledTextField,
  disabled
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: '24px',
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, marginRight: '16px' }}>
          {isEditing ? (
            <div style={{ marginBottom: '20px' }}>
              <StyledTextField
                disabled={disabled}
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Space Name"
                variant="outlined"
                fullWidth
                sx={{
                  marginBottom: '20px',
                  '& .MuiOutlinedInput-input': {
                    fontSize: '24px',
                    fontWeight: 600,
                    marginBottom: '20px',
                  },
                }}
              />
            </div>
          ) : (
            <h1
              style={{
                fontSize: '32px',
                fontWeight: 600,
                color: '#1976d2',
                marginBottom: '16px',
                marginTop: 0,
              }}
            >
              {name}
            </h1>
          )}

          {isEditing ? (
            <StyledTextField
              disabled={disabled}

              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              sx={{
                marginBottom: '24px',
                marginTop: '16px',
              }}
            />
          ) : (
            <p
              style={{
                fontSize: '16px',
                lineHeight: 1.6,
                color: '#666',
                marginBottom: '24px',
              }}
            >
              {description}
            </p>
          )}
        </div>

        {/* Edit / Save / Cancel Buttons */}
        <div>
          {isEditing ? (
            <div style={{ display: 'flex', gap: '8px' }}>
              <IconButton
                disabled={disabled}

                onClick={handleCancelClick}
                size="large"
                sx={{
                  color: '#d32f2f',
                  backgroundColor: 'rgba(211, 47, 47, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(211, 47, 47, 0.15)',
                  },
                }}
              >
                <CancelOutlined />
              </IconButton>
              <IconButton
                disabled={disabled}

                onClick={handleSaveClick}
                size="large"
                sx={{
                  color: '#2e7d32',
                  backgroundColor: 'rgba(46, 125, 50, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(46, 125, 50, 0.15)',
                  },
                }}
              >
                <SaveOutlined />
              </IconButton>
            </div>
          ) : (
            <IconButton
              disabled={disabled}

              onClick={handleEditClick}
              size="large"
              sx={{
                color: '#1976d2',
                backgroundColor: 'rgba(25, 118, 210, 0.08)',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.15)',
                },
              }}
            >
              <EditOutlined />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default NameDescriptionSection;
