import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Box
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ChipList from "../common/ChipList";
import HomeCardItem from "./HomeCardItem";
import { useSelector } from "react-redux";
import ExtensionLoginSuccess from "../Extension/ExtensionLoginSuccess";
import { useNavigate } from "react-router-dom";
import ai from "../../Assets/ai.png";
import AiDialog from "../Ai/AiDialog";
import SpacesFilter from "../common/SpacesFilter"
const Reviews = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const [searhTags, setSearchTags] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedSpaces,setSelectedSpaces] =useState([]);
  const [searchItem, setsearchItem] = useState("");
  const [filterBy, setFilterBy] = useState("all");
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removedRateId, setRemovedRateId] = useState(null);

  const [openAiDialog, setOpenAiDialog] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Delete specific item from local storage
      localStorage.removeItem('yourItemKey');
    };

    // Add event listener for page refresh/unload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const deleteRate = () => {
    let url = API_URL + `/rates/${removedRateId}/delete`;
    axios
      .post(url, null)
      .then((res) => {
        getRatesRequest(null, filterBy, searhTags);
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: "Rate removed successfully",
            type: "success",
          },
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };

  const onRateSuccessfully = (id) => {
    let url = API_URL + `/rates/${id}`;
    axios
      .get(url)
      .then((res) => {
        const ratedObj = res.data.rated_object.rate;
        setData(data.map((obj) => (obj.id === id ? (obj = ratedObj) : obj)));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };
  const token = useSelector((state) => state.token);
  const filterRates = useSelector((state) => state.filterRates);

  useEffect(() => {
    if (token) {

      getRatesRequest(null, filterBy, searhTags)
    }

  }, [token]);
  
  useEffect(() => {
    if (filterRates &&filterRates!==null) {

      setData(filterRates)
    }

  }, [filterRates]);
  


  useEffect(() => {
    let isExtension = localStorage.getItem('is_extension_login')
    if (isExtension) {
      navigate('/extension-login-success')

    }

  }, []);

  useEffect(() => {
    if (token && (filterBy || searhTags||selectedSpaces)) {
      console.log(selectedSpaces)
      getRatesRequest(null, filterBy, searhTags);

    }

  }, [filterBy, searhTags, token,selectedSpaces]);

  const getRatesRequest = (search_query, search_type, tags) => {
    let url = API_URL + `/rates/my-rates`;
    let paramsBody = {
      tags: tags.length > 0 ? tags.join(',') : null,
      spaces: selectedSpaces.length > 0 ? selectedSpaces.join(',') : null,

    };
    axios
      .get(url, {

        params: paramsBody,
      })
      .then((res) => {
        setData(res.data.rates);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        console.log(err)
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };

  const onTagAdded = (e) => {
    if (e.key === "Enter") {
      let newSearcgTags = [...searhTags, searchItem];
      setSearchTags(newSearcgTags);
      setsearchItem("");
    }
  };

  const handleSearchTagDelete = (index) => {
    let newSearcgTags = searhTags.filter((f, i) => i !== index);
    setSearchTags(newSearcgTags);
  };

  const selectFromPrev = (item) => {
    let newSearcgTags = [...searhTags, item];
    setSearchTags(newSearcgTags);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuCheckbox = (name) => {
    setFilterBy(name);
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: "34px", width: "100%" }}>
      <Grid item xs={12}>
        <h2>My Reviews</h2>
        <div style={{ display: 'flex', alignItems: 'center' }}>

          <FormControl className="searchbar" fullWidth variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
            <Input
              fullWidth
              onKeyDown={onTagAdded}
              className="searchBox"
              id="standard-adornment-password"
              value={searchItem}
              onChange={(e) => setsearchItem(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />

          </FormControl>
          {/* <img src={ai} style={{ width: '24px', marginLeft: '8px', cursor: 'pointer' }} alt="icon" onClick={() => { setOpenAiDialog(true) }} /> */}
        </div>
        <ChipList list={searhTags} onDelete={handleSearchTagDelete} />
        {!token && (
          <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px' }}>
            <Box style={{ backgroundColor: "#f9f9f9", padding: "40px", borderRadius: "8px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", marginTop: '50px' }}>
              <Grid
                container
                spacing={2}
                style={{ paddingTop: "20px", width: "100%" }}
              >
                <Grid item xs={12} md={12} lg={11} style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "1.2rem", color: "#666", margin: "20px 0" }}>
                    Unlock exclusive content and features by <a href="/login" style={{ color: "#007BFF", textDecoration: "none", fontWeight: "600" }}>signing in</a>. Experience the best we have to offer.
                  </p>
                  <a
                    href="/login"
                    style={{
                      display: "inline-block",
                      padding: "12px 24px",
                      backgroundColor: "#007BFF",
                      color: "#fff",
                      borderRadius: "6px",
                      textDecoration: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      transition: "background-color 0.3s ease"
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#007BFF")}
                  >
                    Sign In Now
                  </a>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
        <div>
        </div>
              <SpacesFilter onSelect={(s)=>{setSelectedSpaces(s)}} searchValue={searchItem}/>

      </Grid>
      <div style={{ display: 'flex', flexWrap: 'wrap', padding: '16px', margin: '0 auto',marginRight:'60px' }}>

      {data.map((m, index) => (
        <Grid item xs={12} sm={2} md={4} key={index} >
          <div style={{ height: '400px', marginBottom: '10px',padding:'10px'}}>
            <HomeCardItem
              selected={selectedCards.includes(m.id)}

              isBookmark={true}
              data={m}
              searhTags={searhTags}
              applyMargin={true}
              showRemove={true}
              onRemove={(id) => {
                setRemoveDialogOpen(true);
                setRemovedRateId(id);
              }}
              onRateSuccessfully
            />
          </div>

        </Grid>
      ))}
      </div>
      <ConfirmationDialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        onConfirm={() => {
          deleteRate();
          setRemoveDialogOpen(false);
        }}
        title={"Delete rate"}
        cancelButtonString={"Cancel"}
        confirmButtonString={"Delete"}
        message={"Are you sure you want to delete this rate?"}
      />
      <AiDialog open={openAiDialog} onClose={() => { setOpenAiDialog(false) }} />
    </Grid>
  );
};

export default Reviews;
