import React from "react";
import { Box, CardHeader, Rating, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import TimelineIcon from "@mui/icons-material/Timeline";
import Done from "@mui/icons-material/Done";

const HomeCardHeader = ({
  data,
  isFeedback,
  displayStars,
  isBookmark,
  onRatingClick,
  showRatePopup,
  onPathClick,
  navigate,
}) => {
  return (
    <CardHeader
      title={
        <>
          <Box>
            {/* Top row: avatar + user name, and possibly rating */}
            <div
              className="d-flex"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {/* Avatar + Username */}
              <Box
                className="userName"
                style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                onClick={() => navigate(`/user/${data.user_id}`)}
              >
                <Avatar
                  className="profilePic"
                  alt="Profile Picture"
                  style={{
                    maxHeight: "30px",
                    maxWidth: "30px",
                    marginRight: "10px",
                  }}
                  src={data.profile_image_url}
                />
                <label>{data.user_name}</label>
              </Box>

              {/* Stars (ratings) */}
              {isBookmark && displayStars && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRatingClick();
                  }}
                >
                  {data.average_stars > 0 && (
                    <Rating
                      style={{ color: "rgba(255, 204, 72, 1)" }}
                      name="hover-feedback"
                      value={data.average_stars}
                      readOnly
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                  )}
                </div>
              )}
            </div>

            {/* Second row: feedback icons (Resolved, timeline, etc.) */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                }}
                className="ratingSection"
              >
                {isFeedback && data.resolved && (
                  <div title={"The customer received an answer"}>
                    <Done className="iconColor done-feedback-sign" color="primary" />
                  </div>
                )}
              </Box>

              {isFeedback && (
                <TimelineIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onPathClick();
                  }}
                  style={{ color: "#128d6f", cursor: "pointer" }}
                />
              )}
            </Box>
          </Box>
        </>
      }
    />
  );
};

export default HomeCardHeader;
