import React from "react";
import EditableFields from "./EditableFields";
import "./Profile.css";

const ProfileInfo = ({ data, dataOriginal, handleFormChange, updateProfile }) => {
  return (
    <div className="profile-info">
      <EditableFields
        label="First name"
        value={data?.first_name}
        fieldName="first_name"
        handleFormChange={handleFormChange}
        updateField={updateProfile}
      />
      <EditableFields
        label="Last name"
        value={data?.last_name}
        fieldName="last_name"
        handleFormChange={handleFormChange}
        updateField={updateProfile}
      />
      <EditableFields
        label="Email"
        value={data?.email}
        fieldName="email"
        handleFormChange={handleFormChange}
        updateField={updateProfile}
      />
      {dataOriginal.email !== data.email && (
        <p className="warning-text">
          Email will be changed only after clicking the confirmation link in your email.
        </p>
      )}
    </div>
  );
};

export default ProfileInfo;
