import React from 'react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const navigate = useNavigate();
  const redirectUrl = localStorage.getItem('redirectAfterLogin');

  React.useEffect(() => {
    console.log('callback function')
    localStorage.removeItem('redirectAfterLogin');
    if (redirectUrl !== 'null' && redirectUrl) {
      console.log('navigate to:', redirectUrl);
      window.history.pushState({}, '', redirectUrl);

      navigate(redirectUrl);
    }
    // Add any additional initialization logic for the authorized user here.
  }, [redirectUrl]);

  return (
    <div>
      <p>Processing callback...</p>
    </div>
  );
};

export default Callback;