import React from "react";
import { Box } from "@mui/material";
import PublisherComment from "./PublisherComment";
import ChipList from "../common/ChipList";

const HomeCardBody = ({ data, searhTags }) => {
  return (
    <>
      {data.content && (
        <Box sx={{ width: "100%" }}>
          <PublisherComment comment={data.content} onClickDisabled />
        </Box>
      )}

      <div style={{ paddingTop: "10px" }}>
        <ChipList list={data.tags} highlightList={searhTags} AllowChipOverflow />
      </div>
    </>
  );
};

export default HomeCardBody;
