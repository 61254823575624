import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./Components/Header";
import Router from "./Components/Router";
// import Sidebar from "./Components/Sidebar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {useMediaQuery} from "@mui/material"
import ScrollToTop from "./Components/common/ScrollToTop";
import ChatComponent from "./Components/AiChat/ChatComponent";

import { API_URL } from "./consts";
import axios from "axios";
import MiniSidebar from "./Components/Sidebar/MiniSidebar";
import { useNavigate } from "react-router-dom";
import { nav } from "aws-amplify";

const theme = createTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "rgb(0,202,153)",
      dark: "rgb(0,202,153)",
    },
    secondary: {
      main: "rgb(0,202,153)",
    },
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          color: "rgb(0,202,153) !important",
        },
      },
    },
  },
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(false);
  const user = useSelector((state) => state.user);

  const authorized = useSelector((state) => state.authorized);
  const token = useSelector((state) => state.token);

  const popupTxt = useSelector((state) => state.popupTxt);
  const [isLocationAuth, setIsLocationAuth] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  


  const state = {
    vertical: "top",
    horizontal: "right",
  };




  useEffect(() => {
    dispatch({
      type: "SET_TOKEN_IN_PROCESS",
      payload: true,
    });
    let token = localStorage.getItem("user_token")
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      dispatch({
        type: "SET_TOKEN",
        payload: token,
      })
      let url = API_URL + `/profile`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          
          dispatch({
            type: "SET_USER_DATA",
            payload: { ...res.data.profile },
          });
          dispatch({
            type: "SET_TOKEN_IN_PROCESS",
            payload: false,
          });
          dispatch({
            type: "SET_REDIRECT_PATH",
            payload: null
          });
          // window.location.reload()
        })
        .catch((error) => {
          dispatch({
            type: "SET_TOKEN_IN_PROCESS",
            payload: false,
          });
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          dispatch({
            type: "SET_REDIRECT_PATH",
            payload: null
          });
        })
      dispatch({
        type: "SET_USER_DATA",
        payload: token,
      });
      dispatch({
        type: "SET_AUTHORIZED",
        user: null,
      });
    } else {
      dispatch({
        type: "SET_TOKEN_IN_PROCESS",
        payload: false,
      });
      dispatch({
        type: "SET_UNAUTHORIZED",
      });
    }
  }, []);
    const dontMinimizeFromLocalStorage = localStorage.getItem("dontMinimize");
  const [sidebarOpen, setSidebarOpen] = useState(
    dontMinimizeFromLocalStorage === "true"
  );

  useEffect(() => {
    console.log(sidebarOpen)
    // any init logic for authorized user, etc.
  }, [sidebarOpen]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box sx={{ display: 'flex' ,paddingBottom:'10px'}}>
          {/* Sidebar */}
          <Box
            component="nav"
       
          >
           <MiniSidebar setProfile={setProfile} onOpenChange={(open)=>{setSidebarOpen(open)}} />
          </Box>

          {/* Main content */}
          <Box
            component="main"
            sx={{
              maxWidth:(sidebarOpen&&!isMobile)?'80%':'100%',
              flexGrow: 1,
              transition: 'margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
          >
            {!isLocationAuth ? (
              <>
                <Snackbar
                  open={!!popupTxt.text}
                  anchorOrigin={state}
                  key={state.vertical + state.horizontal}
                >
                  <Alert severity={popupTxt.type} sx={{ width: "100%" }}>
                    {popupTxt.text}
                  </Alert>
                </Snackbar>
                <Header setProfile={setProfile} />
              </>
            ) : null}
            <Router
              authorized={!isLocationAuth}
              user={authorized}
              setIsLocationAuth={setIsLocationAuth}
            />
            <ChatComponent/>
            <ScrollToTop />

          </Box>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
