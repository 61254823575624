import React from "react";
import { Button, Grid, Box, Avatar, Typography } from "@mui/material";
import Wap from "../../Assets/Wap.svg";
import "./Profile.css";

const PointsSection = ({ showComingSoonMsg }) => {
  return (
    <div className="points-section">
      <Typography variant="h4" gutterBottom>
        What you can do with your points?
      </Typography>
      <Box>
        <Button
          variant="contained"
          onClick={showComingSoonMsg}
          sx={{ marginRight: 2 }}
        >
          Visit points market
        </Button>
        <Button variant="outlined">How to get points?</Button>
      </Box>
      
      <Avatar src={Wap} sx={{ marginTop: 2, width: 80, height: 80 }} />
    </div>
  );
};

export default PointsSection;
