import { TextField } from '@mui/material';
import React from 'react';

/**
 * A small reusable TextField with custom styles.
 */
export const StyledTextField = ({ ...props }) => (
  <TextField
    {...props}
    sx={{
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#f8f9fa',
        '&:hover': {
          backgroundColor: '#f3f4f6',
        },
        '&.Mui-focused': {
          backgroundColor: '#ffffff',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e0e3e7',
      },
      '& .MuiInputLabel-root': {
        color: '#6b7280',
      },
    }}
  />
);
