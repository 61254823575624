import StarsIcon from "@mui/icons-material/Stars";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "../style.css";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import ChipList from "../common/ChipList";
import Level from "../../Assets/Level.svg";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
const OthersProfile = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [searchTags, setSearchTags] = useState([]);
  const [data, setData] = useState({
    company_name: "",
    email: "",
    email_preference: {},
    first_name: "",
    last_name: "",
    plan: "",
    points: 0,
    profile_image_url: "",
    oldPassword: "",
    newPassword: "",
    password: "",
  });
  const [dataOriginal, setDataOriginal] = useState({
    company_name: "",
    email: "",
    email_preference: {},
    first_name: "",
    last_name: "",
    plan: "",
    points: 0,
    profile_image_url: "",
    oldPassword: "",
    newPassword: "",
    password: "",
  });

  useEffect(() => {
    let url = API_URL + `/users/${id}`;

    //get Call will be here
    axios
      .get(url)
      .then((res) => {
        setData(res.data);
        setDataOriginal(res.data);
        setSearchTags(res.data?.tags);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  }, []);
  const getEncryptPassword = (password) => {
    if (!password) return "";
    let passwordStr = "";
    for (let s of password) {
      passwordStr += "*";
    }
    return passwordStr;
  };
  const setField = (fieldName, field_value) => {
    let cloneData = { ...data };
    cloneData[fieldName] = field_value;
    setData(cloneData);
  };

  return (
    <Box>
      <Grid container style={{ paddingTop: "34px", width: "100%" }}>
        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Grid item xs={8}>
            <h2>{data?.user_name}</h2>
            <Box
              style={{
                width: "240px",
                display: "inline-flex",
                marginTop: "20px",
              }}
            >
              <Box className="profile-edit">
                {data.profile_image_url ? (
                  <Avatar
                    className="profileAvatar"
                    alt="Profile Picture"
                    src={data.profile_image_url}
                  />
                ) : null}
              </Box>
              <Box
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
               <Box style={{fontWeight: 500, padding: "0px 10px 4px"}}>
                                   {data?.level_title}
                                </Box>
                <Box
                  className="profile-level"
                  style={{ display: "flex", alignItems: "center"}}
                >
                  <Avatar style={{ marginRight: "8px" }} src={Level} />
                  <Typography component="p">Level {data?.level}</Typography>
                </Box>

                <Box style={{ width: "180px", display: "inline-flex" }}>
                  <Chip
                    className="pointChip"
                    icon={<StarsIcon className="iconStyle" />}
                    label={
                      <Box style={{ marginLeft: "2px" }}>
                        <Box className="pointsStyle">Points</Box>
                        <Box className="pointsStyle2">{data?.points}</Box>
                      </Box>
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Typography
            paragraph
            className="profileItemHeader"
            sx={{ marginBottom: "0px" }}
          >
            First name
          </Typography>
          <Typography paragraph className="paddingStyle">
            {data?.first_name}
          </Typography>
          <Typography
            paragraph
            className="profileItemHeader"
            sx={{ marginBottom: "0px" }}
          >
            Last name
          </Typography>
          <Typography paragraph className="paddingStyle">
            {data?.last_name}
          </Typography>

{/* 
          <Grid className="followers d-flex" sx={{ paddingLeft: "10px" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography component="h3">{data?.followers}</Typography>
              <Typography component="p">Followers</Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography component="h3">{data?.followings}</Typography>
              <Typography component="p">Following</Typography>
            </Box>
          </Grid> */}

          <Grid className="profile-tags">
            <Box className="subHeader">Popular Tags</Box>
            <ChipList list={searchTags?.length ? searchTags : []} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OthersProfile;
