import React, { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { API_URL, popupTimer } from "../../consts";
import axios from "axios";

const ChatComponent = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const dispatch = useDispatch();
    const [isCommunity, setIsCommunity] = useState(false);
    useEffect(() => {
        setIsCommunity(window.location.href.includes("community"))
    
      }, [window.location.href]);
    const handleSendMessage = () => {
        let isSpacePage = window.location.href.includes("/spaces/")

        let messageType =  isSpacePage?"space":"my_reviews"
        if (inputValue.trim()) {
            setMessages((prev) => [...prev, { user: "You", text: inputValue }]);
            setInputValue("");
            setIsTyping(true);
            console.log('is community?',isCommunity)

            let spaceId = ''
            if (isSpacePage) {
                const url = window.location.href; // Get the current URL
                spaceId = url.substring(url.lastIndexOf("/") + 1);
            }
            if(isCommunity){
                messageType='community'
            }
            const url = API_URL + `/rates/ai`;

            axios
                .post(url, {
                    message_type:messageType,
                    command: inputValue,
                    space_id: spaceId,
                    mode: "chat",
                })
                .then((res) => {
                    setIsTyping(false);
                    const systemMessage = res.data.summary || "No response.";
                    setMessages((prev) => [
                        ...prev,
                        { user: "system", text: systemMessage },
                    ]);
                    if (res.data.rates?.length > 0) {
                        dispatch({
                            type: "FILTER_RATES",
                            payload: res.data.rates,
                        });
                    }
                })
                .catch((err) => {
                    setIsTyping(false);
                    const errorMessage =
                        err.response?.data?.error ||
                        "Something went wrong. Please try again later.";
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: errorMessage,
                            type: "error",
                        },
                    });
                    setTimeout(() => {
                        dispatch({
                            type: "SET_POPUP",
                            payload: { text: "", type: "" },
                        });
                    }, popupTimer);
                });
        }
    };

    return (
        <>
            {isChatOpen &&(
                <Paper
                    elevation={6}
                    sx={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        width: "350px",
                        height: "450px",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "12px",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                        overflow: "hidden",
                        animation: "fadeIn 0.3s ease-in-out",
                        "@keyframes fadeIn": {
                            from: { opacity: 0, transform: "translateY(20px)" },
                            to: { opacity: 1, transform: "translateY(0)" },
                        },
                    }}
                >
                    {/* Chat Header */}
                    <Box
                        sx={{
                            backgroundColor: "#00796b",
                            color: "white",
                            padding: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            Personal GPT
                        </Typography>
                        <CloseIcon
                            onClick={() => setIsChatOpen(false)}
                            sx={{ cursor: "pointer", fontSize: "1.5rem" }}
                        />
                    </Box>

                    {/* Chat Messages */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            padding: "15px",
                            overflowY: "auto",
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        {messages.length > 0 ? (
                            messages.map((msg, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        margin: "10px 0",
                                        display: "flex",
                                        justifyContent:
                                            msg.user === "You" ? "flex-end" : "flex-start",
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            padding: "10px 15px",
                                            borderRadius: "12px",
                                            backgroundColor:
                                                msg.user === "You" ? "#00796b" : "#e0e0e0",
                                            color: msg.user === "You" ? "white" : "black",
                                            maxWidth: "70%",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {msg.text}
                                    </Typography>
                                </Box>
                            ))
                        ) : (
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "center",
                                    color: "#757575",
                                    marginTop: "10px",
                                }}
                            >
                                No messages yet.
                            </Typography>
                        )}
                        {isTyping && (
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "left",
                                    color: "#aaa",
                                    fontStyle: "italic",
                                    marginTop: "10px",
                                }}
                            >
                                Processing...
                            </Typography>
                        )}
                    </Box>

                    {/* Chat Input */}
                    <Box
                        sx={{
                            display: "flex",
                            padding: "10px",
                            borderTop: "1px solid #ccc",
                            backgroundColor: "white",
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Type your message..."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleSendMessage();
                            }}
                            sx={{
                                marginRight: "10px",
                                borderRadius: "8px",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#00796b",
                                "&:hover": { backgroundColor: "#005f56" },
                                fontWeight: 600,
                                textTransform: "none",
                                borderRadius: "8px",
                            }}
                            onClick={handleSendMessage}
                        >
                            Send
                        </Button>
                    </Box>
                </Paper>
            )}

            {/* Chat Floating Button */}
            {!isChatOpen && !window.location.href.includes("community") &&<Fab
                color="primary"
                onClick={() => setIsChatOpen((prev) => !prev)}
                sx={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    backgroundColor: "#00796b",
                    "&:hover": { backgroundColor: "#005f56" },
                    boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
                }}
            >
                <ChatIcon />
            </Fab>}
        </>
    );
};

export default ChatComponent;
