import React from "react";
import { Menu, MenuItem, Chip } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteIcon from "@mui/icons-material/Delete";

const HomeCardActionsMenu = ({
  anchorEl,
  openMenu,
  handleClose,
  data,
  showSpacePopup,
  displayRemoveFromSpace,
  onRemoveFromSpaceClick,
  showRemove,
  onRemove,
  isFeedback,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem sx={{ paddingTop: "0", paddingBottom: "0" }}>
        <Chip
          className="viewCount"
          icon={<QrCodeIcon className="menuIcon" />}
          sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
          label={"Add to space"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClose();
            showSpacePopup(data.id);
          }}
        />
      </MenuItem>

      {displayRemoveFromSpace && (
        <MenuItem>
          <Chip
            className="viewCount"
            icon={<DeleteIcon className="menuIcon" />}
            sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
            label={"Remove from current space"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClose();
              onRemoveFromSpaceClick(data.id);
            }}
          />
        </MenuItem>
      )}

      {showRemove && (
        <MenuItem>
          <Chip
            className="viewCount"
            icon={<DeleteIcon className="menuIcon" />}
            sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
            label={"Remove"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClose();
              onRemove(data.id);
            }}
          />
        </MenuItem>
      )}
    </Menu>
  );
};

export default HomeCardActionsMenu;
