import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Box
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
// Sub-components
import HomeCardMedia from "./HomeCardMedia";
import HomeCardHeader from "./HomeCardHeader";
import HomeCardActionsMenu from "./HomeCardActionsMenu";
import HomeCardBody from "./HomeCardBody";

// Common/other components
import StatisticsDialog from "../common/StatisticsDialog";
import RatingObject from "../RatingObject/RatingObject";
import AddToSpace from "./AddToSpace";
import HomeCardDialog from "./HomeCardDialog";
import SummaryEditorDialog from "../Ai/SummaryEditorDialog";


export const showUnauthorizedErrorPopup = (dispatch) => {
  // Dispatch a Redux action to show a popup (e.g. a snackbar/toast)
  dispatch({
    type: "SET_POPUP",
    payload: {
      text: "You must Login to Perform this Action",
      type: "error",
    },
  });

  // Hide the popup after a delay
  setTimeout(() => {
    dispatch({
      type: "SET_POPUP",
      payload: {
        text: "",
        type: "",
      },
    });
  }, popupTimer);
};

const HomeCardItem = ({
  isFeedback,
  isBookmark,
  displayRemoveFromSpace,
  data,
  searhTags,
  applyMargin,
  onRateSuccessfully,
  displayStars,
  onRemoveFromSpaceClick,
  showRemove,
  onRemove,
  selected,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authorized = useSelector((state) => state.authorized);

  // State
  const [readingDialogOpen, setReadingDialog] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [ratingsValue, setRatingsValue] = useState(0);
  const [id, setId] = useState("");
  const [openStatsModal, setOpenStatsModal] = useState(false);

  // For “Add to space”
  const [rateId, setRateId] = useState(null);
  const [openSpaceModal, setOpenSpaceModal] = useState(false);

  // For card menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // For path history dialog (if you want it)
  const [pathDialogOpen, setPathDialog] = useState(false);

  // For the Card details dialog
  const [openedItemData, setDetailedDataDialog] = useState(null);

  // Handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSpaceModalClose = () => {
    setOpenSpaceModal(false);
  };

  const onRatingClick = () => {
    setOpenStatsModal(true);
  };
  const handleStatsDialogClose = () => {
    setOpenStatsModal(false);
  };

  const showSpacePopup = (rateId) => {
    if (authorized) {
      setRateId(rateId);
      setOpenSpaceModal(true);
    } else {
      showUnauthorizedErrorPopup(dispatch);
    }
  };

  const showRatePopup = () => {
    if (authorized) {
      setShowRateModal(true);
      setId(data.id);
    } else {
      showUnauthorizedErrorPopup(dispatch);
    }
  };
  const handleRatingModalClose = () => {
    setShowRateModal(false);
  };

  const handleSave = async (receivedHtml, objectId) => {
    setReadingDialog(false);
    try {
      const { data: responseData } = await axios.post(`${API_URL}/documents`, {
        content: receivedHtml,
        object_id: objectId,
      });
      return responseData;
    } catch (error) {
      console.error("Save error:", error);
      throw error;
    }
  };

  return (
    <>
      <Card
        className="cardItem"
        sx={{
          border: selected ? "2px solid #128d6f" : "none",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
          height: "100%",
        }}
        onClick={() => {
          // Only open the details if we’re NOT opening the “AddToSpace” dialog
          if (!openSpaceModal) setDetailedDataDialog(data);
        }}
      >
        {/* 1) MEDIA (image or fallback background) */}
        <HomeCardMedia data={data} />

        {/* 2) RATINGS DIALOG */}
        <StatisticsDialog
          data={data?.stars}
          total={
            data?.stars &&
            Object.values(data.stars).reduce(
              (partial_sum, a) => partial_sum + a,
              0
            )
          }
          handleClose={handleStatsDialogClose}
          open={openStatsModal}
        />

        {/* 3) HEADER */}
        <HomeCardHeader
          data={data}
          isFeedback={isFeedback}
          displayStars={displayStars}
          isBookmark={isBookmark}
          onRatingClick={onRatingClick}
          showRatePopup={showRatePopup}
          onPathClick={() => setPathDialog(true)}
          navigate={navigate}
        />

        {/* 4) BODY (content area: tags, snippet, etc.) */}
        <CardContent
          sx={{
            position: "relative",
            paddingBottom: "10px", // space for show more
          }}
        >
          <HomeCardBody data={data} searhTags={searhTags} />
        </CardContent>

        {/* MENU ICON (top-right corner) */}
        <Box
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            backgroundColor: "white",
            borderRadius: "4px",
            width: "28px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClick(e);
            }}
            sx={{
              minWidth: "unset",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <MenuIcon sx={{ fontSize: "24px" }} />
          </Button>
        </Box>

        {/* 5) FOOTER ACTIONS (if you have any “Replay,” etc.) */}
        {isBookmark && (
          <>
            <CardActions
              disableSpacing
              sx={{ flexWrap: "wrap", position: "relative" }}
            >
              {isFeedback && (
                <Button
                  sx={{
                    width: "76px",
                    height: "28px",
                    textAlign: "center",
                    color: "white",
                    fontWeight: "500",
                    backgroundColor: "#128d6f",
                    borderRadius: "50px",
                    marginLeft: "20px",
                  }}
                  className="button-rate"
                  onClick={(e) => {
                    e.stopPropagation();
                    showRatePopup();
                  }}
                >
                  Replay
                </Button>
              )}

              <Box
                sx={{
                  marginLeft: applyMargin ? { sm: "0px", md: "auto" } : "auto",
                }}
              />
            </CardActions>
          </>
        )}

        {/* 6) MENU POPUP */}
        <HomeCardActionsMenu
          anchorEl={anchorEl}
          openMenu={openMenu}
          handleClose={handleClose}
          data={data}
          showSpacePopup={showSpacePopup}
          displayRemoveFromSpace={displayRemoveFromSpace}
          onRemoveFromSpaceClick={onRemoveFromSpaceClick}
          showRemove={showRemove}
          onRemove={onRemove}
          isFeedback={isFeedback}
        />
      </Card>

      {/* PATH HISTORY DIALOG (if you have it) */}
      {/* 
      <PathHistoryDialog
        open={pathDialogOpen}
        onClose={() => setPathDialog(false)}
        urls={data.last_urls ? data.last_urls : []}
      />
      */}

      {/* RATING OBJECT DIALOG */}
      <RatingObject
        ratings={ratingsValue}
        open={showRateModal}
        isFeedback={true}
        handleClose={handleRatingModalClose}
        id={id}
        onRateSuccessfully={onRateSuccessfully}
      />

      {/* ADD TO SPACE DIALOG */}
      {openSpaceModal && (
        <AddToSpace
          open={openSpaceModal}
          rateId={rateId}
          handleClose={handleSpaceModalClose}
          authorized={authorized}
        />
      )}

      {/* HOME CARD DIALOG */}
      {openedItemData && (
        <HomeCardDialog
          open={openedItemData}
          data={openedItemData}
          onReadMore={() => setReadingDialog(true)}
          onClose={(e) => {
            e.stopPropagation();
            setDetailedDataDialog(null);
          }}
        />
      )}

      {/* SUMMARY EDITOR DIALOG */}
      <SummaryEditorDialog
        open={readingDialogOpen}
        onSave={(content) => handleSave(content, data.id)}
        onClose={() => setReadingDialog(false)}
        objectId={data.id}
      />
    </>
  );
};

HomeCardItem.defaultProps = {
  displayStars: true,
  displayStats: true,
};

export default HomeCardItem;
