import React from "react";
import { DialogTitle, Box } from "@mui/material";

const TitleSection = ({ title, url, getDomain }) => {
  return (
    <DialogTitle
      sx={{
        textAlign: "center",
        py: 2
      }}
    >
      <Box
        sx={{
          width: "100%",
          fontWeight: "bold",
          fontSize: { xs: "1.2rem", sm: "1.5rem" },
        }}
      >
        {title}
      </Box>
      {url && (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            color: "text.secondary",
            mt: 0.5
          }}
        >
          <span
            onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {getDomain(url)}
          </span>
        </Box>
      )}
    </DialogTitle>
  );
};

export default TitleSection;
