import React from "react";
import {
  Avatar,
  Box,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import Level from "../../Assets/Level.svg";
import StarsIcon from "@mui/icons-material/Stars";

const AvatarSection = ({ data, imageChange }) => {
  return (
    <Box
      className="avatar-section"
      sx={{ display: "flex", flexDirection: "column", gap: 3, p: 2 }}
    >
      {/* Avatar and Profile Information */}
      <Box
        className="avatar-container"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <Avatar
          className="profileAvatar"
          alt="Profile Picture"
          src={data?.profile_image_url}
          sx={{ width: 80, height: 80, cursor: "pointer" }}
          // onClick={() => document.getElementById("icon-button-file").click()}
        />
        <input
          accept="image/*"
          id="icon-button-file"
          type="file"
          onChange={imageChange}
          style={{ display: "none" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {data?.first_name} {data?.last_name}
          </Typography>
          {data?.location && (
            <Typography variant="body2" color="textSecondary">
              {data?.location}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Level and Points Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#f9f9f9",
          p: 2,
          borderRadius: "8px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {data?.level_title}
          </Typography>
          <Box style={{
            marginLeft: '20px',
            display:'flex'
          }}>
            <Avatar src={Level} sx={{ width: 24, height: 24 }} />
            <Typography variant="body2">Level {data?.level}</Typography>
          </Box>
        </Box>

        <Chip
          className="pointChip"
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "6px 10px",
            backgroundColor: "#e3f2fd",
            borderRadius: "16px",
            width: '200px',
            marginLeft: '20px'
          }}
          icon={<StarsIcon sx={{ color: "#1976d2" }} />}
          label={
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#1976d2",
              }}
            >
              {data?.points} Points
            </Typography>
          }
        />
      </Box>

      {/* Plan Information */}
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          backgroundColor: "#f9f9f9",
          p: 2,
          borderRadius: "8px",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          <strong>Your Plan:</strong> {data?.plan_name || "N/A"}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Plan Expiration Date:</strong>{" "}
          {data?.plan_expiration_date
            ? new Date(data?.plan_expiration_date).toLocaleDateString()
            : "N/A"}
        </Typography>
      </Box>
    </Box>
  );
};

export default AvatarSection;
