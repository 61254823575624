import Done from "@mui/icons-material/Done";
import StarIcon from "@mui/icons-material/Star";
import { Avatar, Button, Chip, Divider, Grid, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Rating from "@mui/material/Rating";
import React, { useState } from "react";
import ChipList from "../common/ChipList";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteIcon from '@mui/icons-material/Delete';
import TimelineIcon from '@mui/icons-material/Timeline';
import MenuIcon from "@mui/icons-material/Menu";
import PublisherComment from "../Home/PublisherComment"

const SpaceCardItem = ({
    isFeedback,
    isBookmark,
    displayRemoveFromSpace,
    data,
    searhTags,
    applyMargin,
    displayStars,
    onRemoveFromSpaceClick,
    showRemove,
    onRemove,
    selected
}) => {
    const navigate = useNavigate();
    const [readingDialogOpen, setReadingDialog] = useState(false)
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [showRateModal, setShowRateModal] = useState(false);
    const [ratingsValue, setRatingsValue] = useState(0);
    const [id, setId] = useState("");
    const [openStatsModal, setOpenStatsModal] = useState(false);
    const [rateId, setRateId] = React.useState(null);
    const [openSpaceModal, setOpenSpaceModal] = useState(false);
    // For Dropdown Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openedItemData, setDetailedDataDialog] = React.useState(null);
    const authorized = useSelector((state) => state.authorized);

    const open = Boolean(anchorEl);


    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const getDomain = (url) => {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname;
    }



    const onRatingClick = () => {
        setOpenStatsModal(true);
    };
    const showSpacePopup = (rateId) => {
        if (authorized) {
            setRateId(rateId)
            setOpenSpaceModal(true);
        } else {
            showUnauthorizedError();
        }
    };
    const showRatePopup = () => {
        if (authorized) {
            setShowRateModal(true);
            setId(data.id);
        } else {
            showUnauthorizedError();
        }
    };

    const showUnauthorizedError = () => {
        dispatch({
            type: "SET_POPUP",
            payload: {
                text: "You must Login to Perform this Action",
                type: "error",
            },
        });
        setTimeout(() => {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "",
                    type: "",
                },
            });
        }, popupTimer);
    };

    const getColorForSpace = (data) => {
        const colors = [
            '#ADD8E6', // Light Blue
            '#20B2AA', // Light Sea Green
            '#5F9EA0', // Cadet Blue
            '#00CED1', // Dark Turquoise
            '#7FFFD4', // Aquamarine
            '#4682B4'  // Steel Blue
        ];

        const firstLetter = data['name']['0']

        // Get an index based on the first letter's character code
        const colorIndex = firstLetter.charCodeAt(0) % colors.length;

        return colors[colorIndex];
    };

    const handleClickCard = () => {
        window.open('/spaces/' + data.id, '_blank');
    };
    return (
        <Card className="cardItem" sx={{
            border: selected ? '2px solid #128d6f' : 'none', // Highlight if selected
            display: 'flex', flexDirection: 'column',
            position: 'relative',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", cursor: 'pointer', height: '100%'
        }}
            onClick={() => { handleClickCard() }}
        >
            {data.img_url ? (
                <div
                    style={{
                        width: '100%',
                        minHeight: '45%',
                        maxHeight: '45%',
                        backgroundImage: `url(${data.img_url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative',
                    }}
                >
             

                    {/* Website link (left, same row as menu) */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: 'px 2px',
                            borderRadius: '5px',
                            fontSize: '14px',
                            maxWidth: '70%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                    </Box>

                </div>
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '45%',
                        maxHeight: '45%',
                        backgroundColor: getColorForSpace(data),

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontSize: '32px',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        position: 'relative',
                    }}
                >
                    <Typography variant="h4" component="h4">
                        {data.name[0]}
                    </Typography>
                </Box>
            )}

            <CardHeader
                title={
                    <>
                        <Box>
                            <div
                                className="d-flex"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '10px'
                                }}
                            >
                                {/* User information on the left */}
                                <Box
                                    className="userName"
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => navigate(`/user/${data.user_id}`)}
                                >
                                    <Avatar
                                        className="profilePic"
                                        alt="Profile Picture"
                                        style={{
                                            maxHeight: '30px',
                                            maxWidth: '30px',

                                            marginRight: '10px'
                                        }}
                                        src={data.profile_image_url}
                                    />
                                    <label>
                                        {data.user_name}
                                    </label>
                                </Box>

                                {/* Stars on the right */}
                                {isBookmark && displayStars && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                        onClick={onRatingClick}
                                    >
                                        {data.average_stars > 0 && <Rating
                                            style={{ color: "rgba(255, 204, 72, 1)" }}
                                            name="hover-feedback"
                                            value={data.average_stars}
                                            readOnly
                                            emptyIcon={
                                                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                                            }
                                        />
                                        }
                                    </div>
                                )}
                            </div>

                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    width: "100%",
                                }}
                                className="ratingSection"
                            >
                            </Box>
                        </Box>
                    </>
                }
            />

            <CardContent
                sx={{
                    position: 'relative', // Allows the absolute positioning of "Show More"
                    paddingBottom: '10px', // Add space for the "Show More" link at the bottom
                }}
            >
                {/* New line for comment */}


                {data.name && (
                    <Box
                        sx={{

                            width: "100%", // Ensures the comment spans the entire width
                        }}
                    >
                        <Typography variant="h4" component="h4">
                            {data.name}

                        </Typography>
                    </Box>
                )}
                {data.description && (
                    <Box
                        sx={{

                            width: "100%", // Ensures the comment spans the entire width
                        }}
                    >
                        <PublisherComment comment={data.description} onClickDisabled={true} />
                    </Box>
                )}
                <div style={{ paddingTop: '10px' }}>
                    <ChipList list={data.keywords} highlightList={searhTags} AllowChipOverflow={true} />
                </div>

                {/* <div>
            <Description content={data.content} onClickShowMore={() => setDetailedDataDialog(data)} />
        </div> */}
                {/* Menu (top-right) */}



            </CardContent>

            {isBookmark && (
                <>
                    <CardActions disableSpacing sx={{ flexWrap: "wrap", position: "relative" }}>



                        {isFeedback && <Button
                            sx={{
                                width: "76px",
                                height: "28px",
                                textAlign: "center",
                                color: "white",
                                fontWeight: "500",
                                backgroundColor: "#128d6f",
                                borderRadius: "50px",
                                marginLeft: "20px",
                            }}
                            className="button-rate"
                            onClick={() => {
                                showRatePopup();
                            }}
                        >
                            Replay
                        </Button>

                        }




                    </CardActions>


                </>
            )}


        </Card>
    );
};
SpaceCardItem.defaultProps = {
    displayStars: true,
    displayStats: true
}

export default SpaceCardItem;
