import React from "react";
import { Button, Typography, Divider } from "@mui/material";
import ConfirmationDialog from "../common/ConfirmationDialog";
import "./Profile.css";

const DeleteUserDialog = ({
  deleteOpen,
  setDeleteUserDialog,
  deleteUser,
}) => {
  return (
    <div className="delete-dialog">
      <Divider />
      <Button
        variant="outlined"
        color="error"
        onClick={() => setDeleteUserDialog(true)}
        sx={{ marginTop: 2 }}
      >
        Delete user
      </Button>
      <Typography variant="body2" sx={{ marginTop: 1 }}>
        This action will permanently delete your account and all associated data.
      </Typography>
      <ConfirmationDialog
        open={deleteOpen}
        onClose={() => setDeleteUserDialog(false)}
        onConfirm={deleteUser}
        title="Delete User Dialog"
        cancelButtonString="Cancel"
        confirmButtonString="Delete"
        message="Are you sure you want to delete your current user?"
      />
    </div>
  );
};

export default DeleteUserDialog;
