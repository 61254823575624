import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Box,
    TextField,
} from "@mui/material";
import RichTextEditor from './RichTextEditor';

const AiSummaryEditor = ({ open, onClose, onPerform, value }) => {
    const [editorValue, setEditorValue] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [typedText, setTypedText] = useState("");
    const editorRef = React.useRef();

    useEffect(() => {
        if (value && value !== typedText) {
            let currentIndex = typedText.length;
            const newText = value.substring(currentIndex); // Extract new text only
            if (newText.length > 0) {
                let index = 0;
                const interval = setInterval(() => {
                    if (index <= newText.length) {
                        const newTypedText = typedText + newText.substring(0, index);
                        setTypedText(newTypedText);
                        index++;
                    } else {
                        clearInterval(interval);
                    }
                }, 50); // Adjust the typing speed as needed
            }
        }
    }, [value]); // Run only when `value` changes

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handlePerform();
        }
    };

    const handlePerform = () => {
        // Wrap the user input in <h1> tags to make it large
        const largeText = { inputValue };
        onPerform(largeText);
        setInputValue("");
    };

    const handleClose = () => {
        setEditorValue("");
        setTypedText("");
        onClose();
    };

    const handleRevert = () => {
        setEditorValue(value || "");
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogContent sx={{ position: "relative", paddingBottom: "100px" }}>
                <Box sx={{ height: "400px", overflow: "auto" }}>
                    <RichTextEditor
                        ref={editorRef}
                        defaultValue={typedText}
                        onRevert={handleRevert}
                    />
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        padding: "16px",
                        backgroundColor: "white",
                        borderTop: "1px solid #ddd",
                    }}
                >
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Describe your request"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Type your request here..."
                        InputProps={{
                            style: { fontSize: "1rem" },
                        }}
                    />
                    <Button
                        onClick={handlePerform}
                        variant="contained"
                        color="primary"
                        disabled={!inputValue}
                    >
                        SEND
                    </Button>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AiSummaryEditor;
