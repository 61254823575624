import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import AvatarSection from "./AvatarSection";
import ProfileInfo from "./ProfileInfo";
import PointsSection from "./PointsSection";
import DeleteUserDialog from "./DeleteUserDialog";
import ChangePassword from "../ChangePassword/ChangePassword";
import { API_URL, popupTimer } from "../../consts";

const Profile = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [data, setData] = useState({
    company_name: "",
    email: "",
    email_preference: {},
    first_name: "",
    last_name: "",
    plan: "",
    points: 0,
    profile_image_url: "",
    oldPassword: "",
    newPassword: "",
    password: "",
  });
  const [dataOriginal, setDataOriginal] = useState({ ...data });
  const [deleteOpen, setDeleteUserDialog] = useState(false);
  const authorized = useSelector((state) => state.authorized);

  const handleFormChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (authorized) {
      axios.get(`${API_URL}/profile`)
        .then((res) => {
          setData(res.data?.profile);
          setDataOriginal(res.data?.profile);
          setSearchTags(res.data?.profile?.tags);
        })
        .catch((err) => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err.response.data.error || "Something went wrong",
              type: "error",
            },
          });
          setTimeout(() => dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } }), popupTimer);
        });
    }
  }, [authorized]);

  const imageChange = (e) => {
    let image = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setData({ ...data, profile_image_url: reader.result });
      updateProfile("profile_image_url", reader.result);
    };
    reader.readAsDataURL(image);
  };

  const updateProfile = (fieldName, fieldValue) => {
    axios.put(`${API_URL}/profile`, { ...data, [fieldName]: fieldValue });
  };

  const deleteUser = () => {
    axios.delete(`${API_URL}/profile/delete-user`).then(() => {
      setDeleteUserDialog(false);
      dispatch({ type: "SET_UNAUTHORIZED" });
    });
  };

  const showComingSoonMsg = () => {
    dispatch({ type: "SET_POPUP", payload: { text: "Coming Soon", type: "success" } });
    setTimeout(() => dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } }), popupTimer);
  };

  if (!authorized)
    return (
      <Box>
        <h2>Profile</h2>
        <p>Please log in to view your profile.</p>
        <button onClick={() => (window.location.href = "/login")}>Login</button>
      </Box>
    );

  return (
    <Box>
      <Grid container>
        <AvatarSection data={data} imageChange={imageChange} />
        <ProfileInfo
          data={data}
          dataOriginal={dataOriginal}
          handleFormChange={handleFormChange}
          updateProfile={updateProfile}
        />
        <PointsSection showComingSoonMsg={showComingSoonMsg} />
        <DeleteUserDialog
          deleteOpen={deleteOpen}
          setDeleteUserDialog={setDeleteUserDialog}
          deleteUser={deleteUser}
        />
        <ChangePassword open={showModal} handleClose={() => setShowModal(false)} />
      </Grid>
    </Box>
  );
};

export default Profile;
