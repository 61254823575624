import StarsIcon from "@mui/icons-material/Stars";
import { Avatar, Chip, Divider, Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "./Header.css";
import Menu from "@mui/material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Sidebar from "./Sidebar/Sidebar";
import MiniSidebar from "./Sidebar/MiniSidebar";
import { Auth } from "aws-amplify";

const drawerWidth = 330;

export default function Header({ setProfile }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [animation, setAnimation] = React.useState(false);
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authorized = useSelector((state) => state.authorized);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isVApplicationWrapPresent, setIsVApplicationWrapPresent] =React.useState(false);

  React.useEffect(() => {
    // Check if <div class="v-application--wrap"> exists in the DOM
    const vApplicationWrap = document.querySelector(".v-application--wrap");
    setIsVApplicationWrapPresent(!!vApplicationWrap);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const componentChange = () => {
    setMobileOpen(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    Auth.signOut({ global: true });
    setAnchorEl(null);
    dispatch({
      type: "SET_UNAUTHORIZED",
    });
    navigate("/login");
  };

  console.log("user", user);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="appbar_style"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <Typography variant="h1" className="dataRateHeader">
            <img
              src={`${process.env.PUBLIC_URL}/Datarate_logo_new.svg`}
              style={{ height: "70px" }}
              alt="Datarate Logo"
            />
          </Typography>

          <Divider
            className="headerDivider"
            orientation="vertical"
            variant="middle"
            flexItem
          />

          <div className="profileContainer">
            <Avatar
              className="profilePic"
              alt="Profile Picture"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              src={user && user.profile_image_url ? user.profile_image_url : ""}
            />
            <div className="profileDetails">
              {user && user.first_name ? (
                <div className="userName" onClick={handleClick}>
                  {user.first_name} {user.last_name}
                </div>
              ) : (
                <div className="userName" onClick={handleClick}>
                  Guest
                </div>
              )}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    navigate("/profile");
                    setProfile(true);
                  }}
                >
                  Profile
                </MenuItem>
                {authorized && (
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                )}
                {!authorized && (
                  <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
                )}
              </Menu>
            </div>
            {!document.querySelector(".v-application--wrap") &&<button
              className="downloadExtension"
              onClick={() =>
                window.open(
                  "https://chromewebstore.google.com/detail/datarate/gdjiledfnpkdcjocdiicgcagklenhfdl",
                  "_blank"
                )
              }
            >
              Download extension
            </button>
            }
            {user&&user.email && !user.plan_id && (
              <button
                className="upgradeButton"
                onClick={() => navigate("/plans")}
              >
                Upgrade
              </button>
            )}
          </div>

        </Toolbar>
      </AppBar>
      <Box component="nav" aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          transitionDuration={1000}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Sidebar setProfile={setProfile} componentChange={componentChange} />
        </Drawer>
      </Box>
    </Box>
  );
}
