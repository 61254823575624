import React from 'react';
import { Chip, IconButton, InputAdornment } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';

/**
 * Subcomponent: Handles the list of keywords & the "Add keyword" input.
 */
const KeywordsSection = ({
  isEditing,
  keywords,
  setKeywords,
  newKeyword,
  setNewKeyword,
  handleAddKeyword,
  handleKeyDown,
  StyledTextField,
}) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          marginBottom: isEditing ? '16px' : 0,
        }}
      >
        {keywords.map((keyword, index) => (
          <Chip
            key={index}
            label={keyword}
            onDelete={
              isEditing
                ? () => setKeywords(keywords.filter((k) => k !== keyword))
                : undefined
            }
            sx={{
              backgroundColor: '#e3f2fd',
              color: '#1976d2',
              '& .MuiChip-deleteIcon': {
                color: '#1976d2',
                '&:hover': {
                  color: '#d32f2f',
                },
              },
            }}
          />
        ))}
      </div>

      {isEditing && (
        <div style={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
          <StyledTextField
            value={newKeyword}
            onChange={(e) => setNewKeyword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Add keyword"
            size="small"
            sx={{ maxWidth: '200px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleAddKeyword} edge="end" size="small">
                    <AddOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default KeywordsSection;
