import React from "react";
import { Box } from "@mui/material";

function getDomain(url) {
  if (!url) return "";
  const parsedUrl = new URL(url);
  return parsedUrl.hostname;
}

function isYouTubeUrl(url) {
  if (!url) return false;
  const domain = getDomain(url);
  return domain.includes("youtube.com") || domain.includes("youtu.be");
}

function getYouTubeEmbedUrl(url) {
  try {
    const urlObj = new URL(url);
    if (urlObj.hostname.includes("youtu.be")) {
      return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`;
    }
    if (urlObj.hostname.includes("youtube.com") && urlObj.searchParams.has("v")) {
      return `https://www.youtube.com/embed/${urlObj.searchParams.get("v")}`;
    }
  } catch (e) {
    return "";
  }
  return "";
}

function getColorForUrl(url) {
  const colors = [
    "#ADD8E6", // Light Blue
    "#20B2AA", // Light Sea Green
    "#5F9EA0", // Cadet Blue
    "#00CED1", // Dark Turquoise
    "#7FFFD4", // Aquamarine
    "#4682B4", // Steel Blue
  ];

  if (!url) return colors[0];

  const domain = new URL(url).hostname.replace("www.", "");
  const firstLetter = domain[0]?.toLowerCase() || "a";
  const colorIndex = firstLetter.charCodeAt(0) % colors.length;
  return colors[colorIndex];
}

const HomeCardMedia = ({ data ,minHeight,maxHeight}) => {
  const domain = data?.page_id?.href ? getDomain(data.page_id.href) : "";

  // If the content is a YouTube video, display it
  if (data?.page_id?.href && isYouTubeUrl(data.page_id.href)) {
    const embedUrl = getYouTubeEmbedUrl(data.page_id.href);
    return (
      <div
        style={{
          width: "inherit",
          minHeight: (!minHeight)?"45%":minHeight,
          maxHeight: (!maxHeight)?"45%":maxHeight,
          position: "relative",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={embedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ borderRadius: "4px" }}
        ></iframe>

        {/* Website link (top-left corner) */}
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "2px 4px",
            borderRadius: "5px",
            fontSize: "14px",
            maxWidth: "70%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(data.page_id.href, "_blank");
          }}
          title={data?.page_id?.href}
        >
          {domain}
        </Box>
      </div>
    );
  }

  // If there is an image_url, display it
  if (data?.image_url) {
    return (
      <div
        style={{
          width: "inherit",
          minHeight: (!minHeight)?"45%":minHeight,
          maxHeight: (!maxHeight)?"45%":maxHeight,
          backgroundImage: `url(${data.image_url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
      >
        {/* Price label if it exists */}
        {data.price && (
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "50px",
            }}
          >
            <div
              style={{
                fontStyle: "italic",
                borderRadius: "5px",
                maxWidth: "120px",
                backgroundColor: "white",
                textAlign: "center",
                opacity: 0.8,
              }}
            >
              {data.currency && data.currency !== "unknown" ? (
                <b>
                  {data.price}
                  {data.currency}
                </b>
              ) : (
                <b>{data.price_label}</b>
              )}
            </div>
          </Box>
        )}

        {/* Website link (left, same row as menu) */}
        {data?.page_id?.href && (
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              left: "10px",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: "2px 4px",
              borderRadius: "5px",
              fontSize: "14px",
              maxWidth: "70%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(data.page_id.href, "_blank");
            }}
            title={data?.page_id?.href}
          >
            {domain}
          </Box>
        )}
      </div>
    );
  }

  // Otherwise, show the fallback color and first letter of domain
  const letter = domain.replace("www.", "")[0]?.toUpperCase() || "?";

  return (
    <Box
      sx={{
        width: "inherit",
        minHeight: (!minHeight)?"45%":minHeight,
        maxHeight: (!maxHeight)?"45%":maxHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColorForUrl(data?.page_id?.href),
        color: "#fff",
        fontSize: "32px",
        fontWeight: "bold",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      {/* Website link (top-left corner) */}
      {data?.page_id?.href && (
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "14px",
            maxWidth: "70%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(data.page_id.href, "_blank");
          }}
          title={data?.page_id?.href}
        >
          {domain}
        </Box>
      )}

      <div style={{ fontSize: "60px" }}>{letter}</div>
    </Box>
  );
};

export default HomeCardMedia;
