import React, { useState, useImperativeHandle, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const HIGHLIGHT_COLORS = [
    { name: "Yellow", value: "#FFFF00" },
    { name: "Light Green", value: "#CCFFCC" },
    { name: "Cyan", value: "#CCFFFF" },
    { name: "Pink", value: "#FFCCCC" },
    { name: "Orange", value: "#FFCC99" },
    { name: "Purple", value: "#E0CCFF" },
    { name: "Light Gray", value: "#E0E0E0" },
    { name: "Beige", value: "#F5F5DC" },
];

const COLOR_OPTIONS = [
    { name: "Black", value: "black" },
    { name: "White", value: "white" },
    { name: "Red", value: "red" },
    { name: "Blue", value: "blue" },
    { name: "Green", value: "green" },
    { name: "Purple", value: "purple" },
    { name: "Orange", value: "orange" },
    { name: "Gray", value: "gray" },
    { name: "Pink", value: "pink" },
    { name: "Brown", value: "brown" },
];

const FONT_SIZES = [
    "10px", "12px", "14px", "16px", "18px", "20px", "22px", "24px", "26px", "28px", "32px", "36px",
];

const RichTextEditor = React.forwardRef(({ defaultValue, originalContent,height='300px' }, ref) => {
    const [value, setValue] = useState("");
    const [originalValue, setOriginalValue] = useState("");

    const [history, setHistory] = useState([]); // Stack for undo
    const [redoStack, setRedoStack] = useState([]); // Stack for redo
    const quillRef = React.useRef();

    useImperativeHandle(ref, () => ({
        getContent: () => value,
    }));

    React.useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);
    React.useEffect(() => {
        if (originalContent) {
            setOriginalValue(originalContent);
        }
    }, [originalContent]);
    const handleChange = (content) => {
        setHistory((prev) => [...prev, value]); // Save current value to history
        setRedoStack([]); // Clear redo stack when making a new change
        setValue(content);
    };



    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ size: FONT_SIZES }],
                [{ color: COLOR_OPTIONS.map((option) => option.value) }],
                [{ background: HIGHLIGHT_COLORS.map((option) => option.value) }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                ["clean"],
                ["undo", "redo", "revert", "ai"]

            ],
        },
    };

    const formats = [
        "header",
        "font",
        "size",
        "color",
        "background",
        "bold",
        "italic",
        "underline",
        "strike",
        "align",
        "list",
        "bullet",
        "link",
        "image",
    ];
    useEffect(() => {
        if (quillRef.current) {
            const toolbar = quillRef.current.getEditor().getModule('toolbar');

            // Override the undo/redo handlers
            toolbar.addHandler('undo', () => {
                const quill = quillRef.current.getEditor();
                quill.history.undo();
            });

            toolbar.addHandler('redo', () => {
                const quill = quillRef.current.getEditor();
                quill.history.redo();
            });
            toolbar.addHandler('revert', () => {
                const quill = quillRef.current.getEditor();
                console.log('revert handler')
            });
        }
    }, [quillRef.current]);
    // Custom Quill toolbar icons
    useEffect(() => {
        const undoButton = document.querySelector('.ql-undo');
        const redoButton = document.querySelector('.ql-redo');
        const aiButton = document.querySelector('.ql-ai');

        if (undoButton) {
            undoButton.innerHTML = '';
            const undoIcon = document.createElement('span');
            undoIcon.innerHTML = `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 7v6h6"></path><path d="M21 17a9 9 0 0 0-9-9 9 9 0 0 0-6 2.3L3 13"></path></svg>`;
            undoButton.appendChild(undoIcon);

            // Add Undo functionality
            undoButton.addEventListener('click', () => {
                const quill = document.querySelector('.ql-container').__quill;
                if (quill) {
                    quill.history.undo();
                }
            });
        }

        if (redoButton) {
            redoButton.innerHTML = '';
            const redoIcon = document.createElement('span');
            redoIcon.innerHTML = `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 7v6h-6"></path><path d="M3 17a9 9 0 0 1 9-9 9 9 0 0 1 6 2.3L21 13"></path></svg>`;
            redoButton.appendChild(redoIcon);

            // Add Redo functionality
            redoButton.addEventListener('click', () => {
                const quill = document.querySelector('.ql-container').__quill;
                if (quill) {
                    quill.history.redo();
                }
            });
        }
        // if (aiButton) {
        //     const aiIcon = document.createElement('span');
        //     aiIcon.style.cssText = `
        //       display: inline-block;
        //       padding: 4px 8px;
        //       height: 28px;
        //       border: 2px solid currentColor;
        //       border-radius: 4px;
        //       font-weight: bold;
        //       font-family: 'Arial', sans-serif;
        //       font-size: 14px;
        //       text-transform: uppercase;
        //       text-align: center;
        //       cursor: pointer;
        //     `;
        //     aiIcon.textContent = 'AI';
        //     aiIcon.title = 'Perform AI Action';

        //     aiButton.appendChild(aiIcon);

        //     // Add AI functionality

        //     // Add AI functionality
        //     aiButton.addEventListener('click', () => {
        //         // Perform your AI action here
        //         console.log('AI action performed!');
        //     });

        // }

        // Cleanup event listeners on component unmount
        return () => {
            if (undoButton) {
                undoButton.removeEventListener('click', () => { });
            }
            if (redoButton) {
                redoButton.removeEventListener('click', () => { });
            }
        };
    }, []);

    useEffect(() => {
        if (originalContent) {
            console.log('original content exisit')
            const revertButton = document.querySelector('.ql-revert');

            if (revertButton) {

                revertButton.innerHTML = '';
                const revertIcon = document.createElement('span');
                revertIcon.innerHTML = `<svg title="Revert to original document" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M2.5 2v6h6"></path><path d="M2.66 15.57a10 10 0 1 0 .57-8.38"></path></svg>`;
                revertButton.appendChild(revertIcon);
                // Add revert functionality
                revertButton.addEventListener('click', () => {
                    console.log('clicked revert')
                    setValue(originalContent)


                });
                return () => {

                    if (revertButton) {
                        revertButton.removeEventListener('click', () => { });
                    }
                };
            }
        }

    }, [originalContent])

    return (
            <ReactQuill
                theme="snow"

                value={value}
                onChange={handleChange}
                modules={modules}
                formats={formats}
                style={{  height:height}}
            />
    );
});

export default RichTextEditor;
